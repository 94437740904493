.HeaderUserStatus {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #656565;
  z-index: 10;
  line-height: 16px;
}

.runningClock {
  font-size: 16px;
  flex: 0 1 32px;
}