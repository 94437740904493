.lastClockingStatus {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .title {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    color: #999;
    margin: 14px 0 6px;
  }

  .date {
    font-size: 16px;
  }

  .faded {
    opacity: 0.65;
  }