@import-normalize;
@import "./reset.scss";

$background-color: #fff;
$text-color: #000;

// Dark mode variables
$dm-text-color: #fff;
$dm-background-color: #000;

// Disable user selection for app like experience
* {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.userSelectable {
  user-select: text; /* supported by Chrome and Opera */
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
}

// Allowing interaction on input and text area
input,
textarea {
  user-select: text; /* supported by Chrome and Opera */
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
}

html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
}

html,
body,
button,
textarea {
  font-family: 'Roboto', sans-serif;
  background: #f6f6f6;
  margin: 0;
  padding: 0;
}

body {
  font-size: 1.1rem;
  line-height: 1.2rem;
  color: $text-color;
  background: $background-color;
  color: #222222;
}
