.Home {
  height: 100vh;
  display: flex;
  flex-direction: column;
}


.MainContent {
  flex: 1 1 auto;
  background-color: #f6f6f6;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.buttonWrapper {
  height: 160px;
  width: 100%;
  text-align: center;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}

.UnallowedIpAlert {
  font-weight: 600;
  color: #656565;
  padding-bottom: 2px;
  text-align: center;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    margin-bottom: 20px;
  }
}

.Footer {
  flex: 0 1 20px;
  padding: 16px 32px;
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  color: #999999;
  flex: 0 1 auto;
}

