.HeaderClock {
  height: 110px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 0;
  transition: background-color 0.5s;  
  flex: 0 1 110px;
}

.clockDate {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 24px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
}

.clockTime {
  font-size: 36px;
  line-height: 36px;
  font-weight: bold;
}
