@import "../../utils.scss";

.Button {
  will-change: auto;
  height: 140px;
  width: 100%;
  max-width: 540px;
  padding: 12px 15px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  border-radius: 12px;
  background: #03AC00;
  border: 0;
  outline: none;
  box-shadow: inset 0px -8px 0px rgba(0, 0, 0, 0.2);
  transition: margin-top 0.1s, height 0.1s, box-shadow 0.1s, background-color 0.5s;
  cursor: pointer;

  &:active {
    margin-top: 8px;
    height: 132px;
    box-shadow: inset 0px 0 0px rgba(0, 0, 0, 0.2);
  }

  &:disabled{
    background-color: #CCCCCC;
    cursor: default;
  }
}

.secondary {
  background-color: #DB0000;
}
