.contentWrapper {
    transition: transform 0.3s ease-in-out;
    position: fixed;
    bottom: 0px;
    width: 100vw;
    box-sizing: border-box; 
    font-weight: 600;
    color: #656565;
    text-align: center;
    line-height: 22px;
    font-size: 26px;
    word-break: break-word;
    transform: translateY(100%);
  }
  .show {
    transform: translateY(0);
  }

  .header {
    padding: 18px 16px;
    background: white;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    svg { 
      margin-right: 6px;
    }
  }

  .content {
    overflow: hidden;
    height: 100%;
    padding: 16px;
    font-size: 14px;
    background: #F6F6F6;
    border-top: 1px solid #d1d1d1;
    border-bottom: 1px solid #d1d1d1;
  }

  .footer {
    padding: 16px 32px;
    background: white;
  }
  .Button {
    padding: 0;
    margin: 0;
    height: 44px;
    width: 100%;
    box-shadow: none;
    font-size: 12px;
    line-height: 0px;
    border-radius: 6px;
    background: #0071B3;
    opacity: 0.8;
    &:hover {
      opacity: 0.9;
    }
    &:active {
      opacity: 1;
      background-color: #0071B3;
      margin-top: 0;
      height: 44px;
      box-shadow: none;
    }
  }